<template>
  <v-card max-width="1000">
    <v-card-title>Ver deudas</v-card-title>
    <v-card-text>
      <v-form>
        <v-container>
          <v-row align="center" justify="center">
            <v-col>
              <v-select :items="Carreras" label="Carrera" item-text="Nombre" item-value="IdPrograma" v-model="SelCar" :loading="Cargando"></v-select>
            </v-col>
            <v-col sm="2">
              <v-btn color="primary" :loading="Cargando" :disabled="this.bloqueado" @click="ListarDeudas">Ver Deudas</v-btn>
            </v-col>
          </v-row>
          <v-tabs>
            <v-tab>Datos de Facturación</v-tab>
          </v-tabs>
          <br/>
          <v-row>
            <v-col>
              <v-text-field label="Razon Social" v-model="RazonSocial" counter maxlength="250"></v-text-field>
            </v-col>
            <v-col>
              <v-text-field label="NIT/CI" v-model="Nit" maxlength="15"></v-text-field>
            </v-col>
            <v-col>
              <v-text-field label="Complemento" v-model="Complemento" maxlength="15"></v-text-field>
            </v-col>
          </v-row>
          <br/>
          <v-simple-table dense>
            <thead>
              <th>Cargo</th>
              <th>Gestion</th>
              <th>Cobrar</th>
              <th>Multa</th>
              <th>Fecha Limite</th>
              <th>Total a Pagar</th>
              <th>Acción</th>
            </thead>
            <tbody>
              <tr v-for="item in Cargos" :key="item.Nro">
                <td class="text-left">{{ item.Cargo }}</td>
                <td class="text-center">{{ item.Gestion }}</td>
                <td class="text-right">{{ item.Costo==""? "0" : parseFloat(item.Costo).toFixed(2) }}</td>
                <td class="text-right">{{ item.Multa==""? "0" : parseFloat(item.Multa).toFixed(2) }}</td>
                <td class="text-center">{{ item.FechaLimite }}</td>
                <td class="text-right"><span class="font-weight-bold">{{ item.TotalCobrar==""? "0" : parseFloat(item.TotalCobrar).toFixed(2) }}</span></td>
                <td>
                  <div v-if="item.Primero == true">
                    <Cobrar :Carrera="SelCar" :IdServicio="item.IdServicio" :Gestion="item.Gestion" 
                            :Cargo="((item.Multa>0) ? item.Cargo+' y Multas':item.Cargo)" :Monto="item.Cobrar" :Multa="item.Multa"
                            :RazonSocial="RazonSocial" :Nit="Nit" :Complemento="Complemento"></Cobrar>
                  </div>
                  <div v-else>
                  </div>
                  
                </td>
              </tr>
            </tbody>
          </v-simple-table>

          <br/>

          <v-bottom-navigation v-model="value">
            <v-switch v-model="TodoSemestre" :label="`Pagar todo el semestre. `"></v-switch>

            <v-btn @click="LlamarCobroExterno" href="https://recaudodigital.sintesis.com.bo/suitepagos-ui/company/USFA" target="_blank">
              <span>Realizar Pago</span>

              <v-icon>mdi-shopping</v-icon>
            </v-btn>

            
          </v-bottom-navigation>

        </v-container>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import Cobrar from "@/components/Cobrar"
export default {
    name: "DeudasCaja",
    components:{Cobrar},
    data() {
        return {
            Cargando: false,
            SelCar: 0,
            Carreras: [],
            Cargos: [],
            bloqueado: false, //this.$store.state.bloquear
            RazonSocial:'',
            Nit:'',
            Complemento:'',
            Seleccion:{},
            value:'',
            TodoSemestre:false,
        };
    },
    methods: {
        ListarDeudas: function () {
            this.Cargando = true;
            if (this.SelCar != 0) {
                if (this.$store.state.usuario.IdPersona <= 0) {
                    this.$router.push("/Login");
                }
                else {
                  //console.log(this.SelCar);
                  this.Seleccion = this.Carreras.find(Carrera=>{
                    return Carrera.IdPrograma==this.SelCar;
                  });
                  //console.log(this.Seleccion);
                  this.$http.get("/economia/deudascaja/" + this.Seleccion.IdPrograma, { "headers": { "Authorization": "Bearer " + this.$store.state.usuario.Token, } })
                      .then(response => {
                        //console.log(response.data.Data);
                        this.Cargos = response.data.Data;
                  })
                      .catch(e => {
                      if (e.response == undefined) {
                          this.$store.commit("logout");
                          this.$router.push("/Login");
                      }
                  });
                }
            }
            this.Cargando = false;
        },
        FormatoFecha: function (Fecha) {
            let date = new Date(Fecha);
            return date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
        },
        LlamarCobroExterno: function(){
          this.Cargando = true;
            if (this.SelCar != 0) {
                if (this.$store.state.usuario.IdPersona <= 0) {
                    this.$router.push("/Login");
                }
                else {
                  //console.log(this.SelCar);
                  this.Seleccion = this.Carreras.find(Carrera=>{
                    return Carrera.IdPrograma==this.SelCar;
                  });
                  
                  this.$http.get("/economia/lanzadorexterno/" + this.Seleccion.IdPrograma + "/" + this.TodoSemestre, 
                    { "headers": { "Authorization": "Bearer " + this.$store.state.usuario.Token, } })
                      .then(response => {
                      /* if(response.data.Codigo==1)
                      {
                        console.log(response.data.Codigo);
                        window.open('https://recaudodigital.sintesis.com.bo/suitepagos-ui/company/USFA', '_blank');
                      }
                      this.Cargos = response.data.Data; */
                  })
                      .catch(e => {
                      if (e.response == undefined) {
                        console.log(e.response);
                        this.$store.commit("logout");
                        this.$router.push("/Login");
                      }
                  });
                }
            }
            this.Cargando = false;
        },
    },
    mounted() {
        
        if (this.$store.state.usuario.IdPersona <= 0) {
            this.$router.push("/Login");
        }
        else {
            //Axios.
            this.Cargando = true;
            this.$http.get("/student/carreras", { "headers": { "Authorization": "Bearer " + this.$store.state.usuario.Token, } })
                .then(response => {
                this.Carreras = response.data.Data;
                this.Carreras.unshift({IdPrograma:0,IdSede:0,Nombre:'Seleccione la Carrera',LimiteModulo:0,LimiteSemestre:0,IdClasificadorTDC:0});
            })
                .catch(e => {
                if (e.response == undefined) {
                    this.$store.commit("logout");
                    this.$router.push("/Login");
                }
            });
            this.Cargando = false;
        }
    },
}
</script>