<template>
  <nav>
    <v-app-bar flat app class="deep-orange">
      <v-app-bar-nav-icon class="white--text" @click="drawer=!drawer"></v-app-bar-nav-icon>
      <v-toolbar-title class="text-uppercase white--text">
        <span class="font-weight-light">USFA</span>
        <span>-Antares</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn text color="white" @click="logOut">
        <span>Salir</span>
        <v-icon right>mdi-exit-to-app</v-icon>
      </v-btn>
    </v-app-bar> 

    <v-navigation-drawer app v-model="drawer" class="blue-grey">
      <v-layout column align-center>
        <v-flex class="mt-5">
          <v-avatar size="120"> 
            <img :src="UserA.Foto" alt="">
          </v-avatar>
        </v-flex>
        <div class="white--text subheading mt-1 text-sm-center">{{ UserA.Nombre }}</div>
        <div class="white--text subheading mt-1 text-sm-center">{{ UserA.NroIdentidad }}</div>
        <div class="white--text subheading mt-1 text-sm-center">{{ UserA.GesRom }}</div>
      </v-layout>
      
      <v-list>
        <v-list-item-group>
          <v-list-item  v-for="link in links" :key="link.text" router :to="link.route">
            <v-list-item-icon>
              <v-icon v-text="link.icon" class="white--text"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="link.text" class="white--text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
        
      </v-list>
    </v-navigation-drawer>
  </nav>

</template>

<script>
export default {
  name: 'Navbar',

  data() {
    return{
      drawer:false,
      links:[
        {icon:'mdi-view-dashboard',text:'Inicio',route:'/'},
        //{icon:'mdi-account-details',text:'Datos Personales',route:'/Datos'},
        {icon:'mdi-format-list-checks',text:'Asignación de Materias',route:'/Asignacion'},
        {icon:'mdi-printer',text:'Imprimir Asignación',route:'/RepAsignacion'},
        {icon:'mdi-text-box-check',text:'Ver Notas',route:'/VerNotas'},
        {icon:'mdi-cash-register',text:'Deudas',route:'/Deudas'},
        //{icon:'mdi-account-cash',text:'Pagos',route:'/Pagos'},
      ], 
      UserA: this.$store.state.usuario      
    }
  },

  computed:{},

  methods:{
    logOut: function (){
      this.$store.commit('logout');
      this.$router.push('/Login');
    }
  }
};

</script>
