<template>
  <v-card max-width="800">
    <v-card-title>Ver Notas de Materias Asignadas</v-card-title>
    <v-card-text>
      <v-form>
        <v-container>
          <v-row align="center" justify="center">
            <v-col>
              <v-select :items="Carreras" label="Carrera" item-text="Nombre" item-value="IdPrograma" v-model="SelCar" @change="ListarGestiones" :loading="Cargando"></v-select>
            </v-col>
            <v-col sm="3">
              <v-select :items="Gestiones" label="Gestión" item-text="GesRom" item-value="Gestion" v-model="SelGes" :loading="Cargando"></v-select>
            </v-col>
            <v-col sm="2">
              <v-btn color="primary" :loading="Cargando" :disabled="this.Bloqueado" @click="ListarMaterias">Ver Notas</v-btn>
            </v-col>
          </v-row>
          <v-simple-table dense>
            <thead>
              <th>Nro.</th>
              <th>Modulo</th>
              <th>Materia</th>
              <th>Nota</th>
            </thead>
            <tbody>
              <tr v-for="item in Materias" :key="item.Nro">
                <td>{{ item.Nro }}</td>
                <td>{{ item.Modulo }}</td>
                <td>{{ item.Nombre }}</td>
                <td>{{ item.NotaFinal }}</td>
              </tr>
            </tbody>
          </v-simple-table>

        </v-container>
      </v-form>
    </v-card-text>

    <!--
    <v-alert text outlined color="deep-orange" icon="mdi-fire">
      Estamos haciendo ajustes en esta opción para una mejor experiencia.
      Disculpas por las molestias.
    </v-alert>
    -->
    
    
  </v-card>

</template>

<script>
export default {
  name:'VerNotas',
  data(){
    return{
      Cargando:false,
      SelCar:0,
      SelGes:0,
      Carreras:[],
      Gestiones:[],
      Materias:[],
      Seleccion:{},
      Bloqueado:this.$store.state.bloquear
    }
  },

  methods:{
    ListarGestiones:function(){
      this.Cargando=true;
      this.Gestiones=[];
      if (this.SelCar!=0)
      {
        
        if(this.$store.state.usuario.IdPersona<=0){
          this.$router.push('/Login');
        }
        else{
          this.Seleccion = this.Carreras.find(Carrera=>{
            return Carrera.IdPrograma==this.SelCar;
          });
          //console.log(this.Seleccion);
          this.$http.get('/student/allgestiones/'+this.Seleccion.IdSede+'/'+this.Seleccion.IdPrograma,
                        {'headers':{'Authorization':'Bearer '+this.$store.state.usuario.Token,}})
                    .then(response=>{
                      //console.log(JSON.stringify(response.data));
                      this.Gestiones=response.data.Data;
                      this.Gestiones.unshift({Gestion:0,Periodo:0,Semestre:0,Romano:'',GesRom:'Seleccione'});
                    })
                    .catch(e=>{
                      if(e.response==undefined){
                        this.$store.commit('logout');
                        this.$router.push('/Login');
                      }

                    });
        }

      }
      this.Cargando=false;
    },

    ListarMaterias:function(){
      this.Cargando=true;
      this.Materias=[];
      if((this.SelCar!=0) && (this.SelGes!=0)){
        if(this.$store.state.usuario.IdPersona<=0){
          this.$router.push('/Login');
        }
        else{
          this.Seleccion = this.Carreras.find(Carrera=>{
            return Carrera.IdPrograma==this.SelCar;
          });

          this.$http.get('/student/materiasgestion/'+this.Seleccion.IdSede+'/'+this.Seleccion.IdPrograma+'/'+this.SelGes,
                        {'headers':{'Authorization':'Bearer '+this.$store.state.usuario.Token,}})
                    .then(response=>{
                      console.log(response.data);
                      this.Materias=response.data.Data;
                    })
                    .catch(e=>{
                      if(e.response==undefined){
                        this.$store.commit('logout');
                        this.$router.push('/Login');
                      }
                    })
        }
      }

      this.Cargando=false;
    }

  },

  mounted(){
    if(this.$store.state.usuario.IdPersona<=0){
      this.$router.push('/Login');
    }
    else{
      //Axios.
      this.Cargando=true;
      this.$http.get('/student/carreras',
                    {'headers':{'Authorization':'Bearer '+this.$store.state.usuario.Token,}})
                .then(response=>{
                  this.Carreras=response.data.Data;
                  this.Carreras.unshift({IdPrograma:0,IdSede:0,Nombre:'Seleccione la Carrera',LimiteModulo:0,LimiteSemestre:0,IdClasificadorTDC:0});
                })
                .catch(e=>{
                  if(e.response==undefined){
                    this.$store.commit('logout');
                    this.$router.push('/Login');
                  }
                })
      this.Cargando=false;
      console.log(this.Bloqueado);
    }

  }



}
</script>