<template>
<v-container>
<v-card max-width="800">
    <v-card-title>pagos</v-card-title>
    <v-card-text>
      <v-form>
        <v-container>
          <v-row align="center" justify="center">
            <v-col>
              <v-select :items="Carreras" label="Carrera" item-text="Nombre" item-value="IdCarrera" v-model="SelCar"></v-select>
            </v-col>
            <v-col sm="2">
              <v-btn color="primary" :loading="Cargando" :disabled="this.bloqueado" @click="ListarPagos">Ver Pagos</v-btn>
            </v-col>
          </v-row>
          <v-simple-table dense>
            <thead>
              <th>Nro.</th>
              <th>Sede</th>
              <th>Nro. Factura</th>
              <th>Fecha</th>
              <th>Detalle</th>
              <th>Monto</th>
            </thead>
            <tbody>
              <tr v-for="item in Pagos" :key="item.Nro">
                <td>{{ item.Nro }}</td>
                <td>{{ item.Sede }}</td>
                <td>{{ item.NroFactura }}</td>
                <td>{{ FormatoFecha(item.Fecha) }}</td>
                <td>{{ item.Detalle }}</td>
                <td class="text-right">{{ item.Monto.toFixed(2) }}</td>
              </tr>
            </tbody>
          </v-simple-table>

        </v-container>
      </v-form>
    </v-card-text>
  </v-card>

  <!--Tabla de Beneficios-->
  <v-card max-width="800">
    <v-card-title>Beneficios</v-card-title>
    <v-card-text>
      <v-form>
        <v-container>
          <v-simple-table dense>
            <thead>
              <th>Nro.</th>
              <th>Sede</th>
              <th>Fecha</th>
              <th>Beneficio</th>
              <th>Monto</th>
            </thead>
            <tbody>
              <tr v-for="item in Benes" :key="item.Nro">
                <td>{{ item.IdBeneficio }}</td>
                <td>{{ item.Sede }}</td>
                <td>{{ FormatoFecha(item.Fecha) }}</td>
                <td>{{ item.Beneficio }}</td>
                <td class="text-right">{{ item.Monto.toFixed(2) }}</td>
              </tr>
            </tbody>
          </v-simple-table>

        </v-container>
      </v-form>
    </v-card-text>
  </v-card>
</v-container>
  

  
</template>

<script>
export default {
  name:'VerNotas',
  data(){
    return{
      Cargando:false,
      SelCar:0,
      Carreras:[],
      Pagos:[],
      Benes:[],
      bloqueado:false//this.$store.state.bloquear
    }
  },

  methods:{
    ListarPagos:function(){
      this.Cargando=true;

      if (this.SelCar!=0)
      {
        if(this.$store.state.usuario.IdPersona<=0){
          this.$router.push('/Login');
        }
        else{
          this.$http.get('/economia/pagos/'+this.SelCar,
                        {'headers':{'Authorization':'Bearer '+this.$store.state.usuario.Token,}})
                    .then(response=>{
                      this.Pagos=response.data;

                      this.$http.get('/economia/beneficios/'+this.SelCar,
                                    {'headers':{'Authorization':'Bearer '+this.$store.state.usuario.Token,}})
                                .then(response=>{
                                  this.Benes=response.data;
                                })

                    })
                    .catch(e=>{
                      if(e.response==undefined){
                        this.$store.commit('logout');
                        this.$router.push('/Login');
                      }

                    });
        }

      }
      this.Cargando=false;
    },

    FormatoFecha: function(Fecha){
      let date = new Date(Fecha);
      return date.getDate()+'/'+(date.getMonth()+1)+'/'+date.getFullYear();
    }

  },

  mounted(){
    if(this.$store.state.usuario.IdPersona<=0){
      this.$router.push('/Login');
    }
    else{
      //Axios.
      this.Cargando=true;
      this.$http.get('/student/carreras/'+this.$store.state.usuario.IdPersona+'/'+this.$store.state.usuario.Version,
                    {'headers':{'Authorization':'Bearer '+this.$store.state.usuario.Token,}})
                .then(response=>{
                  this.Carreras=response.data;
                  this.Carreras.unshift({IdCarrera:0,Nombre:'Seleccione la Carrera',LimiteModulo:0,LimiteSemestre:0});
                })
                .catch(e=>{
                  if(e.response==undefined){
                    this.$store.commit('logout');
                    this.$router.push('/Login');
                  }
                })
      this.Cargando=false;
    }

  }



}
</script>