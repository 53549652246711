import Vue from 'vue'
import VueRouter from 'vue-router'
import Inicio from '../views/Inicio.vue'
import Login from '../views/Login.vue'
import Asignacion from '../views/Asignacion.vue'
import Datos from '../views/Datos.vue'
import RepAsignacion from '../views/RepAsignacion.vue'
import VerNotas from '../views/VerNotas.vue'
import Deudas from '../views/DeudasCaja.vue' //'../views/Deudas.vue'
import Pagos from '../views/Pagos.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'inicio',
    component: Inicio
  },
  {
    path: '/Login',
    name: 'login',
    component: Login
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    //component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/Asignacion',
    name: 'asignacion',
    component: Asignacion
  },
  {
    path: '/Datos',
    name: 'datos',
    component: Datos
  },
  {
    path: '/RepAsignacion',
    name: 'repasignacion',
    component:  RepAsignacion
  },
  {
    path: '/VerNotas',
    name: 'vernotas',
    component:  VerNotas
  },
  {
    path: '/Deudas',
    name: 'deudas',
    component:  Deudas
  },
  {
    path: '/Pagos',
    name: 'pagos',
    component:  Pagos
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
