<template>
  <v-layout column align-center sm8 class="ma-5">
    <v-form>
      <v-card>
        <v-card-title>Autenticación</v-card-title>
        <v-card-text>
          <v-text-field label="Usuario" v-model="Username"></v-text-field>
          <v-text-field label="Contraseña" :type="'password'" v-model="Pwd"></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="info" v-on:click="autenticar" :loading="cargando">Acceder</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>

    <div>{{ Lista }}</div>
  </v-layout>
</template>

<script>
export default {
  name:'Login',
  data:()=>({
    Username:'',
    Pwd:'',
    Lista:'',
    cargando: false
  }),

  methods:{
    autenticar: function(){
      this.cargando = true;
      
      this.$http.post('/login/authenticate',{Username:this.Username,Pwd:this.Pwd})
           .then(response=>{
             this.Lista=response.data;
             //console.log(this.Lista.ID);
             this.$store.commit('asignaDatosUser',{
               IdPersona:this.Lista.ID,
               Nombre:this.Lista.Nombres,
               Email:this.Lista.Email,
               NroIdentidad:this.Lista.NroIdentidad,
               Gestion:this.Lista.Gestion,
               GesRom:this.Lista.GesRom,
               Token:this.Lista.Token,
               Version:this.Lista.Version,
               IdSede:this.Lista.IdSede,
               Foto:this.Lista.Foto
             });
           })
           .finally(() =>{
             this.cargando=false;
             this.$router.push('/');
           })
      //console.log(this.Lista);
    }
  }
}
</script>
