<template>
  <v-dialog max-width="700px" persistent v-model="dialog">
    <template v-slot:activator="{ on }">
      <v-btn text slot="activator" class="info" v-on="on" @click="findAsignables">Agregar</v-btn>
    </template>
    
    <v-form>
      <v-card>
        <v-card-title>
          <h4>Materias Asignables</h4>
        </v-card-title>

        <v-card-text class="pa-0 ma-0">
          <v-card :color="mat.Elegido? 'blue-grey lighten-1' : 'blue-grey lighten-5'" class="pa-0 ma-0" v-for="mat in Materias" :key="mat.IdParalelo">
            <v-layout row wrap class="pa-0 ma-1">
              <v-container class="pa-0 ma-0" >
                <input type="checkbox" v-model="mat.Elegido" :id="mat.IdParalelo" v-on:change.prevent="ValidarAsignacion(mat,$event)">
                <strong><label :for="mat.IdParalelo">{{mat.Nombre}}</label></strong>
                <!--
                <v-checkbox color="success" v-bind:value="mat.IdParalelo" v-bind:label="mat.Nombre" v-model="mat.Elegido" ></v-checkbox>
                -->
              </v-container>
              <v-layout row wrap class="pa-0 ma-0">{{mat.Semestre}}Sem.</v-layout>
              <v-layout row wrap class="pa-0 ma-0">{{mat.Docente}}</v-layout>
              <v-layout row wrap class="pa-0 ma-0">Cred.{{mat.Creditos}}</v-layout>
              <v-layout row wrap class="pa-0 ma-0">{{mat.Turno}}</v-layout>
              <v-layout row wrap class="pa-0 ma-0">Mod.{{mat.Romano}}</v-layout>
            </v-layout>
            <v-divider></v-divider>
          </v-card>

        </v-card-text>
        



        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" v-bind:loading="this.loading" @click="Reg_Asignacion" :disabled="this.bloqueado">Guardar</v-btn>
          <v-btn color="normal" @click="dialog=false" v-bind:loading="this.loading">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  name:'Asignables',
  props:{
    IdSede:{
      type:Number,
      default:0
      },
    IdPrograma:{
      type:Number,
      default:0
      },
    LimMod:Number,
    LimSem:Number,
    IdClasificadorTDC:{
      type:Number,
      default:0
      },
    MatTomadas:{
      type:Array,
      default:()=>(
        {Modulo:1,Creditos:0},
        {Modulo:2,Creditos:0},
        {Modulo:3,Creditos:0},
        {Modulo:4,Creditos:0},
        {Modulo:5,Creditos:0}
      ),
    },
  },
  data(){
    return{
      dialog:false,
      Auth:{'headers':{'Authorization':'Bearer '+this.$store.state.usuario.Token,}},
      Materias:[],
      matSel:{},
      loading:false,
      bloqueado:this.$store.state.bloquear,
    }
  },
  computed:{
    CostoTotal:function(){
      let SelMat = this.Materias.filter(P=>P.Elegido);
      var Suma = 0;
      for(var i=0;i<SelMat.length;i++){
        Suma+=SelMat[i].Creditos;
      }
      var Costo=this.CostosCredito.find(P=>P.Indice==Suma);
      if (Costo==undefined)
        return 0
      else
        return Costo.Costo.toLocaleString('en-IN');
    }
  },
  methods:{
    findAsignables:function(){
      this.loading=true;
      
      this.$http.get('/student/asignables/'+this.IdSede+'/'+this.IdPrograma+'/'+this.IdClasificadorTDC,this.Auth)
          .then(response=>{
            if(response.data.Codigo==0)
            {
              alert(response.data.Mensaje);
            }
            else
              this.Materias=response.data.Data;
            //this.$http.get('/student/costoscredito/'+hedc,this.Auth)
            //.then(response=>{
              //this.CostosCredito=response.data;
            //});
          })
          .catch(e=>{
            //alert(JSON.stringify(e.response));
            if (e.response==undefined){
              this.$store.commit('logout');
              this.$router.push('/Login');
            }
            else{
              //alert(e);
              alert("No se pueden mostrar las materias asignables.");
            }

          })
          .finally(()=>{
            this.loading=false;
          });
      //alert(JSON.stringify(this.LimSem));
    },
    ValidarAsignacion:function(m,e){
      let MatSele=this.Materias.filter(P=>P.Elegido);
      //alert(JSON.stringify(MatSele));
      //valida si la materia es duplicada.
      MatSele.forEach(Item => {
        //alert(JSON.stringify(Item));
        //alert(JSON.stringify(e));
        if ((Item.IdMateria==m.IdMateria) && (Item.IdParalelo!=m.IdParalelo)){
          m.Elegido=false;
          e.target.checked = m.Elegido;
        }
      });//Fin forEach.

      //valida si existe exceso de creditos modulo.
      let MatMod = MatSele.filter(P=>P.IdModulo==m.IdModulo);
      var Cred=0;
      for(var i=0;i<MatMod.length;i++){
        Cred+=MatMod[i].Creditos;
      }
      //alert(JSON.stringify(m));
      if ((Cred+this.MatTomadas[m.IdModulo-1].Creditos)>this.LimMod){
        m.Elegido=false;
        e.target.checked = m.Elegido;
      }
      //valida si excede numero de creditos semestre.
      var Sem=0;
      for(var j=0;j<MatSele.length;j++){
        Sem+=MatSele[j].Creditos;
      }
      //Se suman las ya asignadas.
      var TotAsig =0;
      this.MatTomadas.forEach(Item => {
        TotAsig+=Item.Creditos;
      });
      if ((Sem+TotAsig)>this.LimSem){
        m.Elegido=false;
        e.target.checked = m.Elegido;
      }
    },
    Reg_Asignacion:function(){
      //alert('Registrando Asignación');
      this.loading=true;
      //const axios = require('axios');
      var data = this.Materias.filter(P=>P.Elegido);

      this.$http.post('/student/registraAsignacion/'+this.IdSede+'/'+this.IdPrograma,{Asigns:data},this.Auth)
      .then(response=>{
        var Err = response.data;
        if (Err.Codigo==1){
          alert(Err.Mensaje);
          this.$router.push('/');
        }
        else{
          alert(Err.Mensaje);
        }
      })
      .catch(e=>{
        if (e.response==undefined){
          this.$store.commit('logout');
          this.$router.push('/Login');
        }
        else{
          alert("Ocurrio un error en el registro de las materias, vuelva a intentarlo.");
        }
      });


    },
  },
  mounted(){
    //alert(JSON.stringify(this.MatTomadas));
    //alert(JSON.stringify(this.LimSem));
  }
}
</script>