<template>
  <v-dialog max-width="600px" v-model="dialog" persistent>
    <template v-slot:activator="{ on }">
      <v-btn text slot="activator" class="info" v-on="on">{{ btnText }}</v-btn>
    </template>
    
    <v-form>
      <v-card>
        <v-card-title><h4>Información Contacto</h4></v-card-title>
        <v-card-text>
          <v-text-field label="Nombre Completo" v-model="Contact.NombreCompleto"></v-text-field>
          <v-layout row wrap>
            <v-flex sm6>
              <v-text-field label="Telefono" v-model="Contact.Telefono"></v-text-field>
            </v-flex>
            <v-flex sm6>
              <v-text-field label="Celular" v-model="Contact.Celular"></v-text-field>
            </v-flex>
          </v-layout>
          <v-layout row wrap>
            <v-flex sm6>
              <v-text-field label="Email" v-model="Contact.Email"></v-text-field>
            </v-flex>
            <v-flex sm6>
              <v-text-field label="Lugar de Trabajo" v-model="Contact.Trabajo"></v-text-field>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="Reg_Contacto">Guardar</v-btn>
          <v-btn color="normal" @click="dialog=false">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  name:'ContactoAddEdi',
  props:{
    Neo:Boolean,
    Contact:Object
  },
  data(){
    return{
      dialog:false,
      error:{},
      Nuevo:this.Neo,
      Auth:{'headers':{'Authorization':'Bearer '+this.$store.state.usuario.Token,}}
    }
  },

  computed:{
    btnText(){
        return this.Neo?"Nuevo":"Editar";
      }
  },

  methods:{
    Reg_Contacto: function(){
      //const axios = require('axios');
      var data = this.Contact;
      this.$http.post('/student/regcontacto/'+this.$store.state.usuario.Version,{data},this.Auth)
      .then(response=>{
        this.error = response.data;
        if (this.error=="")
        {
          this.dialog=false;
        }
        else
        {
          alert(this.error);
        }
      });
    }
  },
}
</script>