<template>
  <div class="Inicio">
    <v-card>
      <v-list-item>
        <v-list-item-content>
          <div class="text-overline mb-4">Bienvenid@</div>
          <v-list-item-title>{{Personales.NombreCompleto}}</v-list-item-title>
          <v-list-item-subtitle>{{Personales.NroIdentidad}}</v-list-item-subtitle>
          <v-list-item-subtitle>{{Personales.Direccion}}</v-list-item-subtitle>
          <v-list-item-subtitle>{{Personales.EmailUSFA}}</v-list-item-subtitle>
        </v-list-item-content>
        
        <v-list-item-avatar tile size="150" color="grey">
          <img :src="Personales.Foto" alt="">
        </v-list-item-avatar>
      </v-list-item>
    </v-card>

    <v-card>
      <v-alert v-if="this.TipoMensaje==='3'" type="error">
        {{Mensaje}}
      </v-alert>
      <v-alert v-if="this.TipoMensaje==='2'" type="warning">
        {{Mensaje}}
      </v-alert>
      <v-alert v-if="this.TipoMensaje==='1'" type="success">
        {{Mensaje}}
      </v-alert>
      <v-data-table hide-default-footer :headers="cabeceras" :items="Documentos" no-data-text="El estudiante no tiene ningun documento registrado">
        <template v-slot:Documentos="props">
          <td>{{props.Documentos.Documento}}</td>
          <td>{{props.Documentos.Tiene}}</td>
          <td>{{props.Documentos.Compromiso}}</td>
        </template>
      </v-data-table>
    </v-card>
    <!--<v-alert dense border="left" type="warning">
      {{EcoMensaje}}
    </v-alert>-->
    <CondicionesUso ref="CUso"></CondicionesUso>
  </div>
</template>

<script>
// @ is an alias to /src
import CondicionesUso from '@/components/CondicionesUso.vue'

export default {
  name: 'Inicio',
  components: {
    CondicionesUso
  },
  
  data() {
   return{
      Auth:{'headers':{'Authorization':'Bearer '+this.$store.state.usuario.Token,}},
      cabeceras:[
          { sortable: false, text: 'Documento', value: 'Documento' },
          { sortable: false, text: 'Entregado', value: 'Tiene' },
          { sortable: false, text: 'Compromiso', value: 'Compromiso' }
        ],
      items:[],
      Personales:'',
      Documentos:[],
      Mensaje:'Documentos',
      TipoMensaje:'3',
      DialogCU:false,
      EcoMensaje:''
   } 
  }, 

  mounted(){
    //console.log(this.$store.state.bloquear);
    this.$refs.CUso.Click();
    
    if (this.$store.state.usuario.IdPersona<=0){
     this.$router.push('/Login');
    }
    else{
      //Verificacion de Documentos.
      //let hed = this.$store.state.usuario.IdPersona+'/';
      //hed += this.$store.state.usuario.Version;

      this.$http.get('/student/data',this.Auth)
            .then(response=>{
              this.items = response.data;
              this.Personales = this.items.Data.DatosGenerales;
              this.Documentos = this.items.Data.Documentos;
              //console.log(this.Documentos);
              //Se verifica que hayan datos.
              //Se recorren los datos recibidos para mostrar el cuadro de mensaje.
              let TieneDocumentos = this.items.Data.TieneDocumentos;
              let message = '';
              let type = this.items.Codigo;
              //console.log(Object.entries(response.data).length);
              if (Object.entries(response.data).length === 0)
              {
                message='Documentos: Debe entregar los documentos faltantes a AA.RR.';
                type='3';
              }
              //console.log('Respueta: ' + JSON.stringify(this.items));
              if (!TieneDocumentos){
                message='Debe entregar sus documentos en AA.RR.';
                type='3';
              }
              else{
                message='Entrego todos los documentos';
                type='1';
              }
              
              this.Mensaje= message;
              this.TipoMensaje = type;
              //console.log(this.Mensaje);
              //console.log(this.Mensaje, this.TipoMensaje);
              //console.log(this.$store.state.bloquear);
              //if(this.TipoMensaje=='1' || this.TipoMensaje=='2')
              //{
                //console.log(this.$store.state.bloquear);
                //this.$store.commit('setDesbloquear');
              //}
            });

      //this.$http.get('student/deudas/'+this.$store.state.usuario.IdPersona+'/'+this.$store.state.usuario.IdSede,this.Auth)
      //.then(response=>{
        //console.log('Economico: '+response.data);
        //if(!response.data)
        //{
          //this.EcoMensaje="No pago la Cuota Inicial del semestre o tiene vencidas las cuotas de la Reprogramación";
          //comentar para evitar el control de cuota inicial.
          //this.$store.commit('setBloquear');
          //console.log('Bloquear: '+this.$store.state.bloquear);
        //}
      //});
      //console.log('El tipo de mensaje que se debe mostrar '+this.TipoMensaje);
      //console.log(this.$store.state.bloquear);
      //Verificacion de las deudas.
      

      
    }
    //alert(this.TipoMensaje);
  }

  

}
</script>
