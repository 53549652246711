import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    usuario:{IdPersona:0,
              Nombre:'USUARIO SIN AUTENTICAR',
              Email:'',
              NroIdentidad:'0',
              Gestion:'0',
              GesRom:'NO DEFINIDO',
              Token:'',
              Version:'0',
              IdSede:0,
              Foto:''
            },
    bloquear:false,//true,//Para que el control sea efectivo colocar en true
  },
  mutations: {
    asignaDatosUser(state,{IdPersona,Nombre,Email,NroIdentidad,Gestion,GesRom,Token,Version,IdSede,Foto}){
      //Gestion=20241;
      //GesRom="2024-I";
      let Per=state.usuario;
      Per.IdPersona=IdPersona;
      Per.Nombre=Nombre;
      Per.Email=Email;
      Per.NroIdentidad=NroIdentidad;
      Per.Gestion=Gestion;
      Per.GesRom=GesRom;
      Per.Token=Token;
      Per.IdSede=IdSede;
      Per.Foto=Foto;
      Per.Version=Version;
      //Tambien se cambia el estado del bloqueo.
      state.bloquear=false;//true;
    },
    logout(state){
      let Per=state.usuario;
      Per.IdPersona=0;
      Per.Nombre='USUARIO SIN AUTENTICAR';
      Per.Email='';
      Per.NroIdentidad='0';
      Per.Gestion=0;
      Per.GesRom='NO DEFINIDO';
      Per.Token='';
      Per.Version=0;
      Per.IdSede=0;
      Per.Foto='';
      //Tambien se cambia el estado del bloqueo.
      state.bloquear=true;//true;//Para que el control sea efectivo colocar en true
    },
    setDesbloquear(state)
    {
      state.bloquear=false;
    },
    setBloquear(state)
    {
      state.bloquear=true;
    },

  },
  actions: {
  },
  modules: {
  }
})
