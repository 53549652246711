<template>
  <v-card>
    <v-card-title>Impresión de la Asignación</v-card-title>
    <v-card-text>
      <v-form>
        <v-container>
          <v-row align="center" justify="center">
            <v-col>
              <v-select :items="Carreras" label="Carrera" item-text="Nombre" item-value="IdPrograma" v-model="SelCar" @change="CargarGestiones"></v-select>
            </v-col>
            <v-col sm="2">
              <v-select :items="Gestiones" label="Gestión" item-text="GesRom" item-value="Gestion" v-model="SelGes" :loading="Cargando"></v-select>
            </v-col>
            <v-col>
              <v-btn color="primary" @click="DescargarArchivo" :loading="Cargando" :disabled="this.bloqueado">Imprimir</v-btn>
            </v-col>
          </v-row>
              
        </v-container>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name:'RepAsignacion',
  data(){
    return{
      Auth:{'headers':{'Authorization':'Bearer '+this.$store.state.usuario.Token,}},
      Carreras:[],
      SelCar:0,
      Seleccion:{},
      Gestiones:[],
      SelGes:0,
      ObjAsignados:{},
      Cargando:false,
      bloqueado:this.$store.state.bloquear,
    }
  },

  methods:{
    CargarGestiones:function(){
      this.Cargando=true;
      this.Gestiones=[];

      if (this.SelCar!=0)
      {
        
        if(this.$store.state.usuario.IdPersona<=0){
          this.$router.push('/Login');
        }
        else{
          //console.log(this.SelCar);
          this.Seleccion = this.Carreras.find((Carrera) => Carrera.IdPrograma==this.SelCar);
          //console.log(this.Seleccion);
          this.$http.get('/student/gestionescosto/'+this.Seleccion.IdSede+'/'+this.Seleccion.IdPrograma, this.Auth)
                    .then(response=>{
                      console.log(response.data);
                      this.Gestiones=response.data.Data;
                      this.Gestiones.unshift({Gestion:0,Periodo:0,Semestre:0,Romano:'',GesRom:'Seleccione'});
                    })
                    .catch(e=>{
                      console.log(e.response);
                      //if(e.response==undefined){
                        //this.$store.commit('logout');
                        //this.$router.push('/Login');
                      //}

                    });
        }

      }
      this.Cargando=false;
    },

    ForzarDescarga:function(res){
      const url =window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download','Asignacion.pdf');
      document.body.appendChild(link);
      link.click();
    },

    DescargarArchivo:function(){
      if (this.SelCar==0)
      {
        alert('Debe seleccionar una carrera.');
      }
      this.Cargando=true;
      this.Seleccion = this.Carreras.find((Carrera) => Carrera.IdPrograma==this.SelCar);
      this.$http.get('/report/repasignacion/'+this.Seleccion.IdSede+'/'+this.Seleccion.IdPrograma+'/'+this.SelGes,{
          responseType: 'arraybuffer',
          'headers':{'Authorization':'Bearer '+this.$store.state.usuario.Token,},
          })
          .then(response=>{
            this.ForzarDescarga(response);
            this.Cargando=false;
          }).catch(err=>{
            if(err.response.status==401)
            {
              this.$router.push('/Login');
            }
          });
    },
  },

  mounted(){
    if (this.$store.state.usuario.IdPersona<=0){
      this.$router.push('/Login');
    }
    else{
      //const axios = require('axios');
      this.$http.get('/student/carreras',this.Auth)
      .then(response=>{
        //Array.prototype.push.apply(this.Menciones,response.data);
        this.Carreras=response.data.Data;
        this.Carreras.unshift({IdPrograma:0,IdSede:0,Nombre:'Seleccione la Carrera',LimiteModulo:0,LimiteSemestre:0,IdClasificadorTDC:0});
        //alert(JSON.stringify(this.Carreras));
        //this.selCar = this.Carreras[0];
      })
      .catch(e=>{
        //alert(JSON.stringify(e.response));
        if (e.response==undefined){
          this.$store.commit('logout');
          this.$router.push('/Login');
        }
      });

    }
  }//Fin Mounted.


}
</script>