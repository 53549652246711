<template>
  <div class="Datos">
    <h1 class="subheading grey--text">Actualizar Datos</h1>

    <v-container >
      <v-flex sm8>
        <v-form>
          <v-card flat >
            <v-card-title>
              <h4>Datos Personales</h4>
            </v-card-title>
            <v-card-text>
              <v-flex>
                <v-text-field label="Dirección" v-model="datos.Direccion"></v-text-field>
              </v-flex>
              <v-layout row wrap>
                <v-flex sm6>
                  <v-combobox label="Zona:" :items="zonas" item-text="Nombre" item-value="IdClasificador" v-model="selzona" required></v-combobox>
                </v-flex>
                <v-flex sm6>
                  <v-text-field label="Email" v-model="datos.Email"></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row wrap>
                <v-flex sm6>
                  <v-text-field label="Teléfono" v-model="datos.Telefono"></v-text-field>
                </v-flex>
                <v-flex sm6>
                  <v-text-field label="Celular" v-model="datos.Celular"></v-text-field>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
          <!--Colegio-->
          <v-card flat class="mt-2">
            <v-card-title><h4>Colegio</h4></v-card-title>
            <v-card-text>
              <v-layout row wrap>
                <v-flex sm6>
                  <v-combobox label="Colegio:" :items="coles" item-text="Nombre" item-value="IdClasificador" v-model="selCole" required></v-combobox>
                </v-flex>
                <v-flex sm6>
                  <v-combobox label="Tipo Colegio:" :items="tipoCole" item-text="Nombre" item-value="IdClasificador" v-model="selTipo"></v-combobox>
                </v-flex>
              </v-layout>
              <v-layout row wrap>
                <v-flex sm6>
                  <v-text-field label="Lugar:" v-model="bachiller.Localidad"></v-text-field>
                </v-flex>
                <v-flex sm6>
                  <v-text-field label="Año Egreso:" v-model="bachiller.AnioEgreso"></v-text-field>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
          <!--Trabajo-->
          <v-card flat class="mt-2">
            <v-card-title><h4>Trabajo</h4></v-card-title>
            <v-card-text>
              <v-layout row wrap>
                <v-flex sm6>
                  <v-text-field label="Institucion" v-model="trabajo.Institucion"></v-text-field>
                </v-flex>
                <v-flex sm6>
                  <v-text-field label="Actividad" v-model="trabajo.Actividad"></v-text-field>
                </v-flex>
              </v-layout>

            </v-card-text>
          </v-card>
          <!--Contacto-->
          <v-card flat class="mt-2">
            <v-card-title><h4>Contactos</h4></v-card-title>
            <v-card-text>
              <div>
                <ContactoAddEdi :Neo="true" :Contact="{
                  IdContacto:0,
                  IdPersona:this.$store.state.usuario.IdPersona,
                  NombreCompleto:'',
                  Telefono:'',
                  Celular:'',
                  Email:'',
                  Trabajo:''
                  }"
                />
              </div>
              <v-card flat color="blue-grey lighten-5" class="ma-1 pa-2" v-for="Item in contacts" :key="Item.IdContacto">
                <v-card-text>
                  <v-layout row wrap>
                    <v-flex sm12>
                      <div class="caption grey--text">Contacto</div>
                      <div>{{ Item.NombreCompleto }}</div>
                    </v-flex>
                    <v-flex sm4>
                      <div class="caption grey--text">Telefono</div>
                      <div>{{ Item.Telefono }}</div>
                    </v-flex>
                    <v-flex sm4>
                      <div class="caption grey--text">Celular</div>
                      <div>{{ Item.Celular }}</div>
                    </v-flex>
                    
                    <div>
                      <ContactoAddEdi :Neo="false" :Contact="Item"/>
                    </div>  
                  </v-layout>
                </v-card-text>
                
              </v-card>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="success" @click="Reg_Datos">Guardar</v-btn>
            </v-card-actions>
          </v-card>
          
        </v-form>
      </v-flex>
      
    </v-container>

  </div>
</template>

<script>
import ContactoAddEdi from '@/components/ContactoAddEdi'

export default {
  name:'Datos',
  components:{ContactoAddEdi},
  data(){
    return{
      datos:{},
      zonas:[],
      selzona:{},
      bachiller:[],
      coles:[],
      selCole:{},
      tipoCole:[],
      selTipo:{},
      trabajo:{},
      contacts:[],
      Auth:{'headers':{'Authorization':'Bearer '+this.$store.state.usuario.Token,}},
      Err:''
    }
  },

  methods:{
    Reg_Datos: function(){
      //signar los datos de los combos donde corresponde.
      this.datos.IdZona=this.selzona.IdClasificador;
      this.bachiller.IdColegio=this.selCole.IdClasificador;
      this.bachiller.IdClasificadorTCO=this.selTipo.IdClasificador;

      //onst axios = require('axios');
      this.$http.post('/student/regdatos/'+this.$store.state.usuario.Version,{
        DatosPersonales:this.datos,
        Colegio:this.bachiller,
        Trabajo:this.trabajo
      },this.Auth)
      .then(response=>{
        this.Err = response.data;
        if (this.Err=="")
        {
          this.$router.push('/');
        }
        else
        {
          alert(this.Err);
        }
      })
      .catch(e=>{
        if (e.status==undefined)
        {
          this.$store.commit('logout');
          this.$router.push('/Login');
        }
      });

    }
  },

  mounted(){
    if (this.$store.state.usuario.IdPersona<=0)
    {//Si no esta autenticado el usuario.
      this.$router.push('/Login');
    }
    else
    {//Se esta autenticado, obtiene los datos del usuario
      //Datos personales.
      //const axios = require('axios');
      
      this.$http.get('/student/data/'+this.$store.state.usuario.IdPersona+'/'+this.$store.state.usuario.Version,{
        'headers':{'Authorization':'Bearer '+this.$store.state.usuario.Token,}
      })
      .then(response=>{
        this.datos=response.data;
        //Lista todas las zonas de acuerdo a departamento.
        this.$http.get('/student/zonas/'+this.datos.IdDepartamento+'/'+this.$store.state.usuario.Version,{
            'headers':{'Authorization':'Bearer '+this.$store.state.usuario.Token,}
          })
          .then(response=>{
            this.zonas=response.data;
            this.selzona= this.zonas.find(P=>P.IdClasificador===this.datos.IdZona);
          })
        //Obtiene los datos del colegio.
        this.$http.get('/student/bachiller/'+this.$store.state.usuario.IdPersona+'/'+this.$store.state.usuario.Version,{
          'headers':{'Authorization':'Bearer '+this.$store.state.usuario.Token,}
        })
        .then(response=>{
          this.bachiller=response.data;
          //this.selCole = this.bachiller.IdClasificador;
          this.$http.get('/student/colegios/'+this.$store.state.usuario.Version,{
            'headers':{'Authorization':'Bearer '+this.$store.state.usuario.Token,}
          })
          .then(response=>{
            this.coles=response.data;
            this.selCole = this.coles.find(C=>C.IdClasificador===this.bachiller.IdColegio);
          })
        })
        this.$http.get('/student/tipocole/'+this.$store.state.usuario.Version,{
          'headers':{'Authorization':'Bearer '+this.$store.state.usuario.Token,}
        })
        .then(response=>{
          this.tipoCole=response.data;
          this.selTipo = this.tipoCole.find(T=>T.IdClasificador===this.bachiller.IdClasificadorTCO);
        })
        //Obtiene los datos del trabajo.
        this.$http.get('/student/trabajo/'+this.$store.state.usuario.IdPersona+'/'+this.$store.state.usuario.Version,{
          'headers':{'Authorization':'Bearer '+this.$store.state.usuario.Token,}
        })
        .then(response=>{
          this.trabajo=response.data;
        })
        //Obtiene los datos del los contactos.
        this.$http.get('/student/contactos/'+this.$store.state.usuario.IdPersona+'/'+this.$store.state.usuario.Version,{
          'headers':{'Authorization':'Bearer '+this.$store.state.usuario.Token,}
        })
        .then(response=>{
          this.contacts=response.data;
        })
      })
      .catch(err=>{
        if (err.status==undefined){
          this.$store.commit('logout');
          this.$router.push('/Login');
        }
      });
      
    }//Fin else.
  }
}
</script>