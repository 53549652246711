<template>
  <v-app>
    
    <navbar/>

    <v-main class="mx-2">
      <router-view></router-view>
    </v-main>

  </v-app>
</template>

<script>
import navbar from './components/Navbar';

export default {
  name: 'App',

  components: {
    navbar,
  },

  data: () => ({
    //
  }),
};
</script>
