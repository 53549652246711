<template>
  <div class="Asignacion">
    <h1 class="subheading grey--text">Asignación/Retiro de materias</h1>
    <v-container>
      <v-flex sm10>
        <v-form>
          <v-card>
            <v-card-title>Asignación de materias</v-card-title>    
            <v-card-text>
              <!--Cabecera del formulario-->
              <v-layout row>
                <v-flex>
                  <v-select label="Carrera" :items="Carreras" item-text="Nombre" item-value="IdPrograma" v-model="selCar"
                  @change="listaAsignadas"></v-select>
                </v-flex>
                <v-spacer></v-spacer>
                <!--Componente Materias Asignables-->
                <Asignables :IdSede="this.Seleccion.IdSede"
                            :IdPrograma="this.Seleccion.IdPrograma"
                            :LimMod="this.Seleccion.LimiteModulo"
                            :LimSem="this.Seleccion.LimiteSemestre"
                            :IdClasificadorTDC="this.Seleccion.IdClasificadorTDC"
                            :MatTomadas="this.ModTomados"
                ></Asignables>
              </v-layout>
              <!--Listado de materias Asignadas-->
              <v-card color="blue-grey lighten-5" class="pa-2 ma-1" v-for="Mat in asignadas" :key="Mat.IdAsignacion">
                <v-container>
                  <v-layout row wrap>
                    <v-flex sm1>
                      <div class="caption grey--text">Sem</div>
                      <div>{{Mat.Semestre}}</div>
                    </v-flex>
                    <v-flex sm10>
                      <div class="caption grey--text">Materia</div>
                      <div class="font-weight-bold">{{Mat.Nombre}}</div>
                    </v-flex>
                    <v-flex sm1>
                      <div class="caption grey--text">Cred</div>
                      <div>{{Mat.Creditos}}</div>
                    </v-flex>
                    <v-layout row sm5>
                      <div class="caption grey--text pa-1">Docente:</div>
                      <div class="pa-1">{{Mat.Docente}}</div>
                    </v-layout>
                    <v-layout row sm2>
                      <div class="caption grey--text pa-1">Turno:</div>
                      <div class="pa-1">{{Mat.Turno}}</div>
                    </v-layout>
                    <v-layout row sm2>
                      <div class="caption grey--text pa-1">Modulo:</div>
                      <div class="pa-1">{{Mat.Romano}}</div>
                    </v-layout>
                    <v-spacer></v-spacer>
                    <!--Aca el componente-->
                    <v-btn v-if="Mat.Diferencia>=0" small text color="info" @click="retiroAsignacion(Mat)" :loading="Cargando" :disabled="Bloqueado">Retirar</v-btn>
                    
                  </v-layout>
                </v-container>
              </v-card>
            </v-card-text>
          </v-card>
        </v-form>
      </v-flex>

      <v-snackbar v-model="Alerta" color="success" v-if="this.Codigo==1">

        {{ Mensaje }}

        <template v-slot:action="{ attrs }">
          <v-btn color="pink" text v-bind="attrs" @click="Alerta = false">
            Cerrar
          </v-btn>
        </template>
      </v-snackbar>

      <v-snackbar v-model="Alerta" color="warning" v-else>

        {{ Mensaje }}

        <template v-slot:action="{ attrs }">
          <v-btn color="pink" text v-bind="attrs" @click="Alerta = false">
            Cerrar
          </v-btn>
        </template>
      </v-snackbar>

    </v-container>
  </div>
</template>

<script>
'use strict';
import Asignables from '@/components/Asignables'
//import func from '../../vue-temp/vue-editor-bridge';

export default {
  name:'Asignacion',
  components:{Asignables},

  data(){
    return{
      asignadas:[],
      Auth:{'headers':{'Authorization':'Bearer '+this.$store.state.usuario.Token,}},
      Carreras:[{IdPrograma:0,IdSede:0,Nombre:'Seleccione la Carrera',LimiteModulo:0,LimiteSemestre:0,IdClasificadorTDC:0}],
      selCar:0,
      ModTomados:[{Modulo:1,Creditos:0},{Modulo:2,Creditos:0},{Modulo:3,Creditos:0},{Modulo:4,Creditos:0},{Modulo:5,Creditos:0}],
      Seleccion:{},
      Cargando:false,
      Bloqueado:this.$store.state.bloquear,
      Mensaje:'Este es un mensaje de prueba.',
      Codigo:0,
      Alerta:false,
    }
  },
  methods:{
    listaAsignadas:function(){
      //alert(JSON.stringify(this.selCar));

      this.Seleccion = this.Carreras.find(Carrera=>{
        return Carrera.IdPrograma==this.selCar;
      })
      //console.log(this.Seleccion)

      this.asignadas=[];
      if (this.selCar>0)
      {
        this.$http.get('/student/asignados/'+this.Seleccion.IdSede+'/'+this.Seleccion.IdPrograma+'/'+this.Seleccion.IdClasificadorTDC,this.Auth)
        .then(response=>{
          //console.log(response.data);
          this.asignadas=response.data.Data;

          let Mods = [{Modulo:1,Creditos:0},
                      {Modulo:2,Creditos:0},
                      {Modulo:3,Creditos:0},
                      {Modulo:4,Creditos:0},
                      {Modulo:5,Creditos:0}];

          this.asignadas.forEach(el => {Mods[el.IdModulo-1].Creditos+=el.Creditos;});
          this.ModTomados=Mods;
          //alert(JSON.stringify(Mods));
        });
      }//Fin If IdCarrera.
     
      //alert(this.Cargando);
     
    },
    //Para eviar el control se debe comentar el IF.
    retiroAsignacion:function(Item){
      //if ((Item.TipoCurso.substring(0,1)=='P')||(Item.Diferencia<0))
      //{
        //alert('No se puede Retirar la Materia');
      //}
      //else
      //{
        //const axios = require('axios');
        this.Cargando=true;

        this.$http.post('/student/registraRetiro/'+Item.IdAsignacion,this.Auth)
        .then(response=>{
          //console.log(response.data);
          this.Mensaje=response.data.Mensaje;
          this.Codigo=response.data.Codigo;
          this.Alerta=true;
          alert('Se realizo el retiro de la materia.');
          this.Cargando=false;
          this.$router.push('/RepAsignacion');
        })

      
      //}
      
    }
  },
  mounted(){
    //alert(this.Cargando);
    //console.log(this.Bloqueado);

    if (this.$store.state.usuario.Token==''){
      this.$router.push('/Login');
    }
    else{
      //const axios = require('axios');

      this.$http.get('/student/carreras',this.Auth)
      .then(response=>{
        //Array.prototype.push.apply(this.Menciones,response.data);
        //console.log(response.data);
        this.Mensaje = response.data.Mensaje;
        this.Codigo = response.data.Codigo;
        this.Carreras=response.data.Data;
        this.Carreras.unshift({IdPrograma:0,IdSede:0,Nombre:'Seleccione la Carrera',LimiteModulo:0,LimiteSemestre:0,IdClasificadorTDC:0});
        //alert(JSON.stringify(this.Carreras));
        //this.selCar = this.Carreras[0];
      })
      .catch(e=>{
        //alert(JSON.stringify(e.response));
        if (e.response==undefined){
          this.$store.commit('logout');
          this.$router.push('/Login');
        }
      });

    }

    //alert(JSON.stringify(this.ModTomados));

  }//Fin Mounted
}
</script>