<template>
  <!-- eslint-disable -->
  <div class="text-center">
    <v-dialog v-model="dialog" width="700" persistent>
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="green lighten-2" dark v-bind="attrs" v-on="on" @click="RegistrarTransaccion">Pagar ahora</v-btn>
        <!--<v-btn color="green lighten-2" dark v-bind="attrs" v-on="on" @click="RegistrarTransaccion">Pagar ahora</v-btn>-->
      </template>

      <v-card >
        <v-card-title class="text-h6 grey lighten-2">
          Por favor espere a que se genere el QR.
        </v-card-title>
        
        <v-alert text outlined color="deep-orange" icon="mdi-fire" v-if="!this.ConDatos">
          No escribio la "Razon Social" ni el "NIT", se requieren esos datos.
        </v-alert>

        <v-card-text v-if="this.ConDatos">
          <v-row no-gutters>
            <!--<v-alert shaped dense dark type="warning">
              Aviso: Se estan haciendo ajustes para mejorar el servicio.
            </v-alert>-->
            <img :src="CodQr" alt="">
            Monto: {{ parseFloat(this.Monto+this.Multa).toFixed(2) }} Bs.<br/>
            Duración: Hasta el final del día.<br/>
            NOTA: Una vez realizado el pago por QR,<br/>
            recibirá un correo de confirmación en su cuenta email USFA con la factura.
          </v-row>

        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialog = false">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
        
</template>

<script>
//import VueBanipay from "@/components/VueBanipay"

export default {
  props:{
    Carrera:{
      type:Number,
      default:0,
    },
    IdServicio:{
      type:Number,
      default:0,
    },
    Gestion:{
      type:String,
      default:"",
    },
    Cargo:{
      type: String,
      default:"",
    },
    Monto:{
      type: Number,
      default:0,
    },
    Multa:{
      type: Number,
      default:0,
    },
    RazonSocial:{
      type:String,
      default:"",
    },
    Nit:{
      type:String,
      default:"",
    },
    Complemento:{
      type:String,
      default:"",
    }
  },
  components:{
      //VueBanipay,
    },
  data(){
    return{
      Pago:{
        Id:0,
        IdPersona:0,
        IdSede:0,
        IdCarrera:0,
        IdServicio:0,
        Gestion:"",
        Cargo:"",
        Monto:0,
        Multa:0,
        RazonSocial:"",
        NIT:"",
        Complemento:"",
      },
      Result:null,
      banipayConfig:{
        "amount":0,
        "currency":"BOB",
        "environmentType": "Prod",
        "businessKey": "02e4b31f-20bd-43f9-9f2d-3ef7733f2d0f",
        "affiliateKey": "0ef291bf-0d70-444a-b949-b4144bba6557",
        "successUrl": "https://banipay.me",
        "failUrl": "https://banipay.me",
        "notificationUrl": "https://banipay.me",
        "description": "",
        "externalCode": "0"
      },
      dialog:false,
      CodQr:"data:image/png;base64,",
      ConDatos:false,
    }
  },
  methods:{
    RegistrarTransaccion: function(){
      if (this.$store.state.usuario.IdPersona <= 0) {
        this.$router.push("/Login");
      }
      else {
        if(this.RazonSocial=='' || this.Nit=='')
        {
          console.log(this.RazonSocial);
          this.ConDatos=false;
          return;
        }
        else{
          this.ConDatos=true;
        }
        //Se arma el objeto a enviar.
        this.Pago.IdCarrera = this.Carrera;
        this.Pago.IdServicio = this.IdServicio; 
        this.Pago.Gestion = this.Gestion;
        this.Pago.Cargo = this.Cargo;
        this.Pago.Monto = this.Monto;
        this.Pago.Multa = this.Multa;
        this.Pago.RazonSocial = this.RazonSocial;
        this.Pago.NIT = this.Nit;
        //console.log(this.Pago)
        //Axios.
        this.$http.post("/economia/transaccion/"+this.Carrera,this.Pago)
        .then(response => {
          //Se toman los datos recibidos.
          this.Result = response.data;
          this.Pago.Id = this.Result.Id;

          //Se asigna datos al objeto BaniPay
          this.banipayConfig.amount = this.Monto+this.Multa;
          this.banipayConfig.currency="BOB";
          this.banipayConfig.environmentType="Prod";
          this.banipayConfig.businessKey="02e4b31f-20bd-43f9-9f2d-3ef7733f2d0f";
          this.banipayConfig.affiliateKey="0ef291bf-0d70-444a-b949-b4144bba6557";
          this.banipayConfig.successUrl="https://banipay.me";
          this.banipayConfig.failUrl="https://banipay.me";
          this.banipayConfig.notificationUrl="https://banipay.me";
          this.banipayConfig.description=this.Cargo;
          this.banipayConfig.externalCode=this.Result.Id.toString();

          //Se asigna el QR.
          this.CodQr = this.CodQr + this.Result.Qr;

          console.log(this.Result);
        }).catch(e => {
          if (e.response == undefined) {
              this.$store.commit("logout");
              this.$router.push("/Login");
          }
        });
      }


    }
  },
  mounted(){
    
  }
}
</script>