import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import axios from 'axios';

Vue.config.productionTip = false
//Agregado por el programador.
axios.defaults.baseURL= 'https://efun.usfa.edu.bo:49230/api'; //'http://localhost:48221/api';

axios.interceptors.request.use(
  (config)=>{
    let token=store.state.usuario.Token;
    if(token){
      config.headers['Authorization']='Bearer '+token;
    }

    return config;
  },
  (error)=>{
    return Promise.reject(error);
  }
);

Vue.prototype.$http = axios;
//Fin del agregado.

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
