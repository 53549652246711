<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="800">
      <template v-slot:activator="{ on }">
        <v-btn text color="blue lighten-2" dark v-on="on">
          Condiciones de Uso
        </v-btn>
      </template>

      <v-card>
        <v-list-item three-line>
					<v-list-item-content>
						<div class="overline mb-4">UNIVERSIDAD PRIVADA SAN FRANCISCO DE ASIS</div>
						<v-list-item-title class="headline mb-1">CONDICIONES DE USO</v-list-item-title>
						<v-list-item-subtitle></v-list-item-subtitle>
					</v-list-item-content>
					<v-list-item-avatar tile size="100" color="grey">
						<v-img src="../assets/UsfaLogoBlue.png" aspect-ratio="1"></v-img>
					</v-list-item-avatar>
				</v-list-item>

        <v-card-text>
          La Universidad Privada San Francisco de Asís (USFA) prestará el servicio de formación universitaria a sus Estudiantes bajo las siguientes condiciones:
					<ol>
						<li>Los estudiantes de la USFA deberán conducirse con respeto y tolerancia hacia todos los miembros de la comunidad universitaria.</li>
						<li>Los estudiantes nuevos se inscribirán en el área de Atención al Público de la USFA, en el semestre académico que corresponda, 
							en la carrera de su preferencia y mediante el formulario preparado para el efecto. Los estudiantes antiguos utilizarán el sistema de inscripciones por internet.</li>
						<li>Las fechas límites de asignación y/o retiro de materias, de cada módulo, están definidas mediante Resolución Rectoral y se dan a conocer mediante el calendario académico.</li>
						<li>El estudiante deberá mantener permanentemente actualizada su información personal en el sistema ANTARES de la USFA.</li>
						<li>El estudiante deberá informarse de manera permanente sobre su situación académica y sobre las disposiciones que sean publicadas por la Universidad.</li>
						<li>El servicio que proporciona la USFA comprende un semestre académico definido por las fechas de inicio y conclusión, señaladas en el calendario académico.</li>
						<li>El costo por derechos académicos se determina en función del número de horas académicas y la tabla “horas académicas – costo” para el semestre académico vigente.</li>
						<li>El estudiante podrá pagar el costo total por derechos académicos del semestre; 1) al contado al inicio del semestre o 
							2) acorde a un plan de pagos establecido en la Resolución Rectoral emitida semestralmente. Adicionalmente, pagará el seguro ofrecido por la USFA.</li>
						<li>El cumplimiento de estos requisitos acreditará al estudiante como “Estudiante Regular” de la USFA.</li>
						<li>El incumplimiento parcial o total del pago por derechos académicos, resultará en la pérdida de su calidad de 
							“Estudiante Regular” y la USFA suspenderá la prestación de los servicios académicos, en base al Art. 573 del Código Civil Boliviano.</li>
						<li>Los servicios académicos podrán restablecerse y el estudiante recuperar su condición de “Estudiante Regular”, 
							mediante el pago de sus obligaciones pendientes más un recargo por incumplimiento en los plazos definidos por la USFA.</li>
						<li>El procesamiento y publicación de las notas finales del estudiante en el semestre académico estarán condicionados al 
							cumplimiento de todas las obligaciones económicas y administrativas.</li>
					</ol>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="Click">Acepto</v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name:'CondicionesUso',
	props:{
    //isDialog:{
			//type:Boolean,
			//default:false
		//}
  },
  data() {
		return{
			dialog:false
		}
	},

	methods:{
		Click:function(){
			this.dialog=!this.dialog;
		}
	}
	
}
</script>